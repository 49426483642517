<template>
  <a-card :bordered="false">
    <SmallSquare content="1、非必要情况下，请不要随意修改地区信息
                              2、如需删除地区信息，请联系技术人员进行操作" />
    <a-tabs default-active-key="1" v-model:activeKey="activeKey">
      <a-tab-pane :key="1" tab="中国">
      </a-tab-pane>
      <a-tab-pane :key="2" tab="海外地区">
      </a-tab-pane>
    </a-tabs>
    <!-- <a-form layout='inline' class="m-t-20">
              <a-form-item label="省份" class="s_po_re">
                  <a-input v-model:value="keywords" placeholder="请输入省份关键字" readonly style="width:220px"></a-input>
              </a-form-item>
              <a-form-item>
                   <a-button type="primary">搜索</a-button>
              </a-form-item>
      </a-form> -->
    <div class="m-t-20" v-if="activeKey==1">
      <div class="s_flex_ail" style="height:46px;line-height:46px;background:#fafafa;border:1px solid #d9d9d9;border-bottom:0;">
        <div class="s_flex_1 p-l-40">名称</div>
        <div class="s_flex_1 p-l-20">行政编码</div>
        <div class="s_flex_1">操作</div>
      </div>
      <a-collapse v-model:activeKey="firstKey" accordion @change="changeProvince">
        <a-collapse-panel v-for="item in province" :key="item.id">
          <template #header>
            <div class="s_flex_ail">
              <span class="s_flex_1">{{item.name}}</span>
              <span class="s_flex_1 m-l-25">{{item.id}}</span>
              <div class="s_flex_1 s_flex_ali" style="margin-left:-25px">
                <a @click.stop="$router.push('/system/regional/add_regional?type=add&name=city&id='+item.id)">新增下级城市</a>
                <a class="m-l-40" @click.stop="openModal(item)">修改</a>
              </div>
            </div>
          </template>
          <!-- 内容 -->
          <a-spin :spinning="cityloading">
            <a-collapse v-model:activeKey="secondKey" accordion @change="changeCity" v-if="city.length!=0">
              <a-collapse-panel v-for="v in city" :key="v.id">
                <template #header>
                  <div class="s_flex_ail">
                    <span class="s_flex_1">{{v.name}}</span>
                    <span class="s_flex_1 m-l-30">{{v.id}}</span>
                    <div class="s_flex_1 s_flex_ali">
                      <a @click.stop="$router.push('/system/regional/add_regional?type=add&name=area&id='+v.id)">新增下级县区</a>
                      <a class="m-l-40" @click.stop="$router.push('/system/regional/edit_regional?type=edit&name=city&id='+v.id)">修改</a>
                    </div>
                  </div>
                </template>
                <!-- 内容 -->
                <a-spin :spinning="arealoading">
                  <!--  -->
                  <div class="area-collapse" v-if="area.length!=0">
                    <div class="s_flex_ail area-collapse-item" v-for="(k,i) in area" :key="k.id">
                      <span class="m-l-10">{{i+1}}、</span>
                      <span class="s_flex_1 m-l-10">{{k.name}}</span>
                      <span class="s_flex_1 m-l-10">{{k.id}}</span>
                      <div class="s_flex_1 s_flex_ali">
                        <a class="m-l-15" @click.stop="$router.push('/system/regional/edit_regional?type=edit&name=area&id='+k.id)">修改</a>
                      </div>
                    </div>
                  </div>
                  <div v-else>暂无地区信息</div>
                </a-spin>
                <!--  -->
              </a-collapse-panel>
            </a-collapse>
            <div v-else>暂无地区信息</div>
          </a-spin>
          <!--  -->
        </a-collapse-panel>
        <!--  -->
      </a-collapse>
    </div>

    <div class="m-t-20" v-if="activeKey==2">
      <div class="s_flex_ail" style="height:46px;line-height:46px;background:#fafafa;border:1px solid #d9d9d9;border-bottom:0;">
        <div class="s_flex_1 p-l-40">地区</div>
        <div class="s_flex_1 p-l-20">行政编码</div>
      </div>
      <a-collapse v-model:activeKey="firstKey" accordion>
        <template #expandIcon="{ isActive }">
          <caret-right-outlined :rotate="isActive ? 90 : 0" />
        </template>
        <a-collapse-panel v-for="item in abroad" :key="item.id">
          <template #header>
            <div class="s_flex_ail">
              <span class="s_flex_1">{{item.name}}</span>
              <span class="s_flex_1 m-l-25">{{item.id}}</span>
            </div>
          </template>
        </a-collapse-panel>
        <!--  -->
      </a-collapse>
    </div>

    <!--  -->
    <a-modal v-model:visible="visible" title="修改省份" destroyOnClose centered @ok="handleOk">
      <a-form :labelCol="{span:4}" :wrapperCol="{span:16}" :model="form" ref="formRef">
        <a-form-item label="省份" required help="为保证数据完整，请输入正确的省份全称" name='name'>
          <a-input placeholder="请输入省份!" v-model:value="form.name"></a-input>
        </a-form-item>
        <a-form-item label="行政编号" class="m-t-20" required>
          <a-input placeholder="请输入省份!" disabled v-model:value="form.id"></a-input>
        </a-form-item>
      </a-form>
    </a-modal>
  </a-card>
</template>
<script>
import { areaList, areaUpdate } from "@/api/system.js";
import { onMounted, reactive, toRefs, ref } from "vue";
import { $iscode } from "@/utils/app";
import { message } from "ant-design-vue";
export default {
  setup() {
    const state = reactive({
      firstKey: 100000,
      secondKey: 0,
      thirdKey: 0,
      province: [],
      city: [],
      area: [],
      abroad: [],
      cityloading: false,
      arealoading: false,
      keywords: "",
      visible: false,
      activeKey: 1,
    });
    const formRef = ref(null);
    const form = ref({
      name: "",
      id: "",
    });

    const methods = {
      async getProvince() {
        const hide = message.loading("加载中...");
        state.province = [];
        // if (state.activeKey == 2) {
        let resrult = await areaList({ pid: 0, is_gw: 1 }).then(
          (res) => res.data
        );
        setTimeout(hide);
        if ($iscode(resrult)) {
          state.abroad = resrult.data || [];
        } else {
          message.error(resrult.msg);
        }
        // } else {
        let res = await areaList({ pid: state.firstKey, is_gw: 0 }).then(
          (res) => res.data
        );
        setTimeout(hide);
        if ($iscode(res)) {
          state.province = res.data || [];
        } else {
          message.error(res.msg);
        }
        // }
      },
      async changeProvince(e) {
        if (e) {
          state.city = [];
          state.cityloading = true;
          let res = await areaList({ pid: state.firstKey }).then(
            (res) => res.data
          );
          setTimeout(() => {
            state.cityloading = false;
          }, 300);
          if ($iscode(res)) {
            state.city = res.data || [];
          } else {
            message.error(res.msg);
          }
        }
      },
      async changeCity(e) {
        if (e) {
          state.area = [];
          state.arealoading = true;
          let res = await areaList({ pid: state.secondKey }).then(
            (res) => res.data
          );
          setTimeout(() => {
            state.arealoading = false;
          }, 300);
          if ($iscode(res)) {
            state.area = res.data || [];
          } else {
            message.error(res.msg);
          }
        }
      },
      handleOk() {
        formRef.value
          .validate()
          .then(async () => {
            const hide = message.loading("加载中...");
            let res = await areaUpdate({ ...form.value }).then(
              (res) => res.data
            );
            setTimeout(hide);
            if ($iscode(res, true)) {
              state.visible = false;
            }
          })
          .catch((err) => {
            message.error("完善表单!");
          });
      },
      openModal(item) {
        form.value = item;
        state.visible = true;
      },
    };
    onMounted(() => {
      methods.getProvince();
    });

    return {
      ...toRefs(state),
      ...methods,
      form,
      formRef,
    };
  },
};
</script>

<style lang="less" scoped>
.s_flex_1 {
  flex: 1;
}
.s_flex_ail {
  display: flex;
  align-items: center;
}
.area-collapse {
  & > .area-collapse-item {
    background: #fafafa;
    border: 1px solid #d9d9d9;
    border-radius: 0 0 2px 2px;
    padding: 12px 16px;
    color: rgba(0, 0, 0, 0.85);
    line-height: 22px;
    cursor: pointer;
    margin-top: -1px;
  }
}
</style>

